module.exports = {
	AWS_STACK_DOMAIN: 'ddtest0148.link',
	AWS_ACCOUNT_ID: '975049955266',
	AWS_DEV_EMAIL: 'daretestricardo@bol.com.br',
	awsHostedZoneId: "Z08734137F9VNH0XBB7N",
	awsAuthApiId: "9959tgafnk",
	twitchClientId: "l1a06uhfmxfcd4b9bi5b0tcf1sh3qf",
	youtubeApiKeyClient: "AIzaSyCCBzYpWRAKHc7aKdklj260Y4D2IjiHLOg",
	twitchPanelClientId: "jkbrarj4zpchol7re86jsn2x0iv4h5",
	twitchOverlayClientId: "o341duupil2ykfk17padw46ygi6odv",
	paypalClientId: "AWImhjYQCBEcXu5OALf_jnhNH0sfh4lVSf34C9vc9OvhPBCedGlN85xIR7tEPQQmbDyeyRaRd9tOs3BZ",
	secretArns: [
		'arn:aws:secretsmanager:us-east-1:975049955266:secret:developmentYoutubeOAuth-eMhgnG',
		'arn:aws:secretsmanager:us-east-1:975049955266:secret:developmentTwitchSecret-XGO1AZ',
		'arn:aws:secretsmanager:us-east-1:975049955266:secret:tiktokOauthSecretDev-f2O5A2',
		'arn:aws:secretsmanager:us-east-1:975049955266:secret:twitchOverlaySecret-fvvgx9',
		'arn:aws:secretsmanager:us-east-1:975049955266:secret:twitchPanelSecret-eyhZXy',
		'arn:aws:secretsmanager:us-east-1:975049955266:secret:PayPal_Test-cDSy8R',
		'arn:aws:secretsmanager:us-east-1:975049955266:secret:paypalApiCertificateDev-lpf3of',
		'arn:aws:secretsmanager:us-east-1:975049955266:secret:cryptoDevelopment-pVjo2b',
		'arn:aws:secretsmanager:us-east-1:975049955266:secret:developmentGoogle-hOvowj',
		'arn:aws:secretsmanager:us-east-1:975049955266:secret:steamWebAPISecrets-amd91m',
	],
}
